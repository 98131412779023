<template>
    <div class="open">
       <div class="navTitle"><img src="@/assets/img/arrl.png" alt="" @click="goBack">开户预约</div>
       <div class="navHeader">
        <div class="search-box flex">          
          <input type="text" placeholder="请输入搜索关键词" v-model="searchVal" @keyup.enter="searchSubmit">
          <img src="@/assets/img/serch.png" @click="searchSubmit">
        </div>
      </div>
        <div class="card">
            <div class="nums">编号：10955</div>
            <div class="company">南宁金电图腾软件有限公司</div>
            <div class="details">
                <p>预约银行：中信银行南宁北秀支行</p>
                <p>预约时间：2020-10-20 11:48:50</p>
                <p>开户成功时间： 2021-11-02 21:29:36</p>
            </div>
            <img class="status" src="@/assets/img/open_daishenhe.png" alt="">
        </div>
        <div class="card">
            <div class="nums">编号：10955</div>
            <div class="company">南宁金电图腾软件有限公司</div>
            <div class="details">
                <p>预约银行：中国工商银行股份有限公司贵港南桥支行</p>
                <p>预约时间：2020-10-20 11:48:50</p>
                <p>开户成功时间： 2021-11-02 21:29:36</p>
            </div>
            <img class="status" src="@/assets/img/open_wancheng.png" alt="">
        </div>
        <van-list
        v-model:loading="loading"
        :finished="finished"
        finished-text="没有更多了"
        @load="onLoad"
        >
        <div class="card" v-for="item in list" :key="item">
            <div class="nums">编号：10955</div>
            <div class="company">南宁金电图腾软件有限公司</div>
            <div class="details">
                <p>预约银行：中国工商银行股份有限公司贵港南桥支行</p>
                <p>预约时间：2020-10-20 11:48:50</p>
                <p>开户成功时间： 2021-11-02 21:29:36</p>
            </div>
            <img class="status" src="@/assets/img/open_wancheng.png" alt="">
        </div>
        </van-list>

    </div>
</template>
<script>
import { ref } from 'vue';
export default {
    setup() {
        const list = ref([]);
        const loading = ref(false);
        const finished = ref(false);

        const onLoad = () => {
        // 异步更新数据
        // setTimeout 仅做示例，真实场景中一般为 ajax 请求
        setTimeout(() => {
            for (let i = 0; i < 1; i++) {
            list.value.push(list.value.length + 1);
            }
            // 加载状态结束
            loading.value = false;
            // 数据全部加载完成
            if (list.value.length >= 2) {
            finished.value = true;
            }
        }, 1000);
        };

        //搜索框键入
        const searchVal = ref('');
        const searchSubmit = () => {
          console.log('搜索值为：',searchVal.value);
        }; 

        return {
        list,
        onLoad,
        loading,
        finished,
        searchVal,
        searchSubmit
        };
    },
    methods:{
        goBack(){
            this.$router.go(-1);
        },
    },
}
</script>
<style lang="less" scoped>
.open {
    padding-top: 12.5vw;    
    .card {
        position: relative;
        .nums {
            display: inline-block;
            color: #ff9900;
            border: 1px solid #ff9900;
            border-radius: 1vw;
            font-size: 4vw;
            padding: 0 2vw;
        }
        .company {
            color: #6699ff;
            font-size: 4.8vw;
            font-weight: 500;
            padding-top: 2vw;
        }
        .details {
            width: 100%;
            font-size: 3.5vw;
            color: #666666;
            padding: 2vw 0;
            p {
            margin: 1.5vw 0;
            }
            
        }
        img.status {
            width: 19.5vw;
            height: auto;
            position: absolute;
            right: 0;
            top: 0;
        }

    }
}
</style>